<template>
  <div>
  <PopupNotificationComponent @snack="handlePopup" v-if="this.popup" :title="this.popupTitle" :text="this.popupText"/>
    <LoadingComponent v-show="checkLoading"></LoadingComponent>
    <v-container>
      <RoalertComponent v-show="isError">
        <div>{{ errorMessage }}</div>
      </RoalertComponent>

      <div class="komunikat_przerwa" v-if="isPrzerwa">{{ komunikatContent }}</div>
      <div class="komentarz"  v-if="isComment" v-html="komentarz"></div>
      <div v-if="!isPrzerwa">

      <h1 class="primary--text">Znajdź wizytę</h1>

      <v-row class="search-wrap">
          <v-col cols="12" xs="12" sm="6">
            <v-autocomplete
              v-model="poradnia"
              :items="poradnie"
              no-data-text="Brak poradni dla wybranych parametrów"
              prepend-inner-icon="mdi-hospital"
              outline
              tile
              item-text="por_nazwa"
              item-value="por_id"
              clearable
              @click:clear="clearPoradnia()"
              @change="SelectedLekItem"
              label="Specjalizacja">
            </v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="6">
            <v-autocomplete
              ref="lekRef"
              v-model="lekarz"
              :items="lekarze"
              prepend-inner-icon="mdi-account"
              outline
              tile
              no-data-text="Nie znaleziono lekarzy"
              item-text="lek_nazwisko"
              item-value="lek_id"
              clearable
              label="Personel"
              @click:clear="clearLekarz"
              @change="SelectedLekItem"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="search-wrap">
        <v-col cols="12" xs="12" sm="6">
            <v-autocomplete
              v-model="usluga"
              :items="uslugi"
              prepend-inner-icon="mdi-stethoscope"
              tile
              outline
              no-data-text="Nie znaleziono usług"
              item-text="mpusl_nazwa"
              item-value="mpusl_id"
              clearable
              label="Usługa"
              @click:clear="clearUsluga"
              @change="SelectedLekItem"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="6">
            <v-autocomplete
              v-model="lokalizacja"
              :items="lokalizacje"
              prepend-inner-icon="mdi-map-marker"
              tile
              outline
              item-text="plac_nazwa"
              item-value="plac_id"
              clearable
              label="Lokalizacja"
              @click:clear="clearLokalizacja"
              @change="SelectedLekItem"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="search-wrap">
          <v-col cols="12" xs="12" sm="6">
            <v-menu
            ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">

            <v-text-field
              v-model="dateRangeText"
              tile
              label="Termin wizyty"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            color="secondary"
            range
            :min="mindata"
            :max="maxdata"
            locale="pl"
            first-day-of-week=1
            selected-items-text=""
          >
          <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu2 = false"
            >
              Zatwierdź
            </v-btn>

          </v-date-picker>
        </v-menu>
          </v-col>

          <v-col class="d-flex" cols="12" xs="6" sm="3">
          <v-select
            :items="godziny | godzfilter"
            v-model="godzod"

            label="od godziny"
          ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" xs="6" sm="3">
          <v-select
            :items="godziny | godzfilter"
            v-model="godzdo"
            label="do godziny"
          ></v-select>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="space-around"
          class="pa-8 searchbutton"
        >
          <v-btn
            elevation="0"
            border="bottom"
            tile
            color="secondary"
            large
            @click="startSearch"
          >
            <v-icon left>
              mdi-magnify
            </v-icon>
            Szukaj
          </v-btn>
        </v-row>
      </div>
        <ContentpageComponent />
    </v-container>

    <transition name="slide-fade">
      <div class="results" v-show="searchResult && !loading && !error">
        <v-container><h2 id="dostepnet" class="primary--text">Dostępne terminy</h2></v-container>
        <ResultComponent v-for="lekdata in searchResult" :lekdata="lekdata" :usl="usluga" :key="lekdata.lek" :nfz="setting_nfz" :lokalizacja="lokalizacja" :wybranaUsluga="wybranaUsluga" @createregister="handleCreateRegister"></ResultComponent>
      </div>
    </transition>

    <v-snackbar
      v-model="snackbar"
      timeout=3000
    >
      {{ snackinfo }}
    </v-snackbar>
  </div>

</template>

<script>
import ResultComponent from './ResultComponent.vue';
import ContentpageComponent from './ContentpageComponent.vue';
import goTo from 'vuetify/es5/services/goto';
import PopupNotificationComponent from './PopupNotificationComponent.vue';


export default {
  name: 'SearchComponent',
  components: {
    ResultComponent,
    ContentpageComponent,
    PopupNotificationComponent,
  },
  data: () => ({
    poradnia: null,
    lekarz: null,
    usluga: null,
    poradnie: [{ por_id: null, por_nazwa: '' }],
    lekarze: [{ lek_id: null, llok: 0, lek_nazwisko: '' }],
    uslugi: [{ mpusl_id: null, mpusl_nazwa: '' }],
    uslugiAll: [],
    lokalizacje: [{ plac_id: 0, plac_nazwa: '' }],
    lokalizacja: null,
    dates: [],
    menu2: false,
    godziny: [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
    godzod: 7,
    godzdo: 17,
    mindata: '0000-00-00',
    maxdata: '0000-00-00',
    loading: false,
    error: false,
    errorMessage: '',
    snackbar: false,
    popup: false,
    popupTitle: 'Odwołanie wizyty',
    popupText: 'Wystąpił błąd przy odwoływaniu wizyty.',
    snackinfo: '',
    searchResult: false,
    url: null,
    terminReady: null,
    komentarz: null,
    guest: true,
    contentPage: null,
    contentType: 0,
    defaultLek : false,
    defaultUsl : false,
    defaultPor : false,
    setting_nfz : false,
    pre_komunikat : false,
    komunikatContent : null,
  }),
  methods: {

    startSearch: function() {
      this.error = false;
      this.searchResult = null;

      if ((this.lekarz==null && this.poradnia==null && this.usluga==null) && (typeof this.lokalizacja !== 'number' || this.lokalizacja === "")) {
          this.errorMessage = 'Wybierz lekarza, poradnię, usługę lub lokalizację';
          this.error = true;
      } else {
        this.loading = true;
        let searchAction = this.checkAction;
        this.$api
        .postData(`/rejestracja/${searchAction}`, this.registerData)
        .then(response => {
            this.appendResult(response.data, searchAction);
          })
        .then(() => {
          this.loading = false;
          this.goTodostepne();
        })
        .catch(error => { console.log(error); this.loading = false; this.showSnackBar('Coś poszło nie tak...'); } );
      }
    },
    checkIfOdwolana(){
      let uri = window.location.href.split('?');
    if(uri.length == 2) {
      let vars = uri[1].split('#');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });
      if(getVars.snack != 1){
        this.popup = true;
        setTimeout(() =>this.popup = false, 6000);
      }
    }
    },
    handlePopup(pop) {
        this.popup = pop;
    },
    goTodostepne() {
      setTimeout(
          function() {
            return goTo('#dostepnet');
          }, 400
        );
    },

    setUsluga(usluga)
    {
      this.wybranaUsluga.nazwa = usluga.nazw;
      this.wybranaUsluga.cena = usluga.wartosc;
      this.wybranaUsluga.czas = usluga.czas;
      this.wybranaUsluga.komentarz = usluga.komentarz;
    },

    appendResult(data, action) {
      this.searchResult = [];
      if ((action ==='searchterminlek') || (action ==='searchterminlekusl'))  {
        // Dla pojedynczego lekarza

        /* if (!(data[0].lista)) {
          this.error = true;
          this.errorMessage = 'Brak wolnych terminów';
          return [];
        } */

        for (let key in data) {
          if (data[0].usluga) this.setUsluga(data[key].usluga);
          let obj = data[key];
          this.searchResult = [];
          this.searchResult.push(obj);
          console.log('OBJ:');
          console.log(obj);

        }
        console.log('RESULT:');
        console.log(this.searchResult);

      } else if((action === 'searchterminusl') || (action === 'searchterminpor')){
        // dla grupy lekarzy
        if (!(data.dane)) {
          this.error = true;
          this.errorMessage = 'Nie znaleziono lekarzy spełniających wybrane kryteria';
          return [];
        }
          this.setUsluga(data.usluga);
          for (let key in data.dane) {
                  let obj = data.dane[key];
                  this.searchResult.push(obj);
                }
      }else {
          if (!(data.dane)) {
          this.error = true;
          this.errorMessage = 'Nie znaleziono lekarzy spełniających wybrane kryteria';
          return [];
        }
          for (let key in data.dane) {
          let obj = data.dane[key];
          this.searchResult.push(obj);
          }
      }
      return this.searchResult;
    },

    // funckja po wybraniu wlasciwosci
    SelectedLekItem() {
      this.searchResult = null;
      this.getRonlineData(this.registerData);
    },

  // funkcja pobiera dane z RO na podstawie parametrow obiektu data
    getRonlineData(data) {
      // LOCAL

      this.loading = true;
        this.$api
        .postData(`/szukaj/updaterejonlinedata`, this.registerData)
        .then(response => {
          if(response.data.data.defaultLek){
            this.lekarz = response.data.data.defaultLek;
            this.defaultLek = true;
          }else{this.defaultLek = false;}
          if(response.data.data.defaultPor){
            this.poradnia = response.data.data.defaultPor;
            this.defaultPor = true;
          }else{this.defaultPor = false;}
          if(response.data.data.defaultUsl){
            this.usluga = response.data.data.defaultUsl;
            this.defaultUsl = true;
          }else{this.defaultUsl = false;}
            this.lekarze = response.data.data.lekarze;
            this.poradnie = response.data.data.poradnie;
            this.uslugi = response.data.data.uslugi;
            this.dates = [response.data.data.dataOd, response.data.data.dataDo];
            this.lokalizacje = response.data.data.lokalizacje;
            this.lokalizacja = response.data.data.lokalizacja;
            this.godzod = response.data.data.godzOd;
            this.godzdo = response.data.data.godzDo;
            this.mindata = response.data.data.dataOd;
            this.maxdata = response.data.data.dataMax;
            this.setting_nfz = response.data.data.setting_nfz;
            this.loading = false
            })
        .catch(error => { console.log(error); this.loading = false; this.showSnackBar('Coś poszło nie tak...'); });
        if(this.usluga != null || this.lekarz != null || this.poradnia != null || this.lokalizacja != null){
        this.error = false;
      }
    },

    showSnackBar(info) {
      this.snackinfo = info;
      this.snackbar = true;
    },

    changePlac() {
      this.loading = true;
      this.poradnia = null,
      this.lekarz = null,
      this.usluga = null,

      this.$api
      .getData(`/changeplac/${this.lokalizacja}`)
      .then(response => {
          this.lekarze = response.data.data.lekarze;
          this.poradnie = response.data.data.poradnie;
          this.uslugi = response.data.data.uslugi;
          this.dates = [response.data.data.dataOd, response.data.data.dataDo];
          this.lokalizacje = response.data.data.lokalizacje;
          this.lokalizacja = response.data.data.lokalizacja;
          this.godzod = response.data.data.godzOd;
          this.godzdo = response.data.data.godzDo;
          this.mindata = response.data.data.dataOd;
          this.maxdata = response.data.data.dataMax;
          this.loading = false;
          })
      .catch(error => { console.log(error); this.loading = false; this.showSnackBar('Coś poszło nie tak...'); } );
        if(this.lokalizacja != null){
        this.error = false;
        }
    },

    clearLekarz() {
        this.lekarz = null;
        if(this.defaultLek && this.defaultPor && this.defaultUsl){
            this.usluga = null;
            this.lokalizacja = null;
            this.poradnia = null;
        }else if(this.defaultPor && this.defaultUsl){
            this.poradnia = null;
            this.lokalizacja = null;
            this.usluga = null;
        }else if(this.defaultPor && this.defaultLek){
            this.lokalizacja = null;
            this.poradnia = null;
        }else if(this.defaultUsl && this.defaultLek){
            this.lokalizacja = null;
            this.usluga = null;
        }
        this.SelectedLekItem();
        if(this.error == true){
          this.error = false;
        }
    },
    clearPoradnia() {
        this.poradnia = null;
        if(this.defaultLek && this.defaultPor && this.defaultUsl){
            this.lekarz = null;
            this.lokalizacja = null;
            this.usluga = null;
        }else if(this.defaultPor && this.defaultUsl){
            this.lokalizacja = null;
            this.usluga = null;
        }else if(this.defaultPor && this.defaultLek){
            this.lokalizacja = null;
            this.lekarz = null;
        }else if(this.defaultUsl && this.defaultLek){
            this.lokalizacja = null;
            this.lekarz = null;
            this.usluga = null;
        }
        this.SelectedLekItem();
        if(this.error == true){
          this.error = false;
        }
    },
    clearUsluga() {
        this.usluga = null;
        if(this.defaultLek && this.defaultPor && this.defaultUsl){
            this.lekarz = null;
            this.lokalizacja = null;
            this.poradnia = null;
        }else if(this.defaultPor && this.defaultUsl){
            this.lokalizacja = null;
            this.poradnia = null;
        }else if(this.defaultPor && this.defaultLek){
            this.lekarz = null;
            this.lokalizacja = null;
            this.poradnia = null;
        }else if(this.defaultUsl && this.defaultLek){
            this.lokalizacja = null;
            this.lekarz = null;
        }
        this.SelectedLekItem();
        if(this.error == true){
          this.error = false;
        }
    },
    clearLokalizacja() {
        this.lokalizacja = null;
        if(this.defaultLek && this.defaultPor && this.defaultUsl){
            this.usluga = null;
            this.lekarz = null;
            this.poradnia = null;
        }else if(this.defaultPor && this.defaultUsl){
            this.usluga = null;
            this.poradnia = null;
        }else if(this.defaultPor && this.defaultLek){
            this.lekarz = null;
            this.poradnia = null;
        }else if(this.defaultUsl && this.defaultLek){
            this.usluga = null;
            this.lekarz = null;
        }
        this.SelectedLekItem();
        if(this.error == true){
          this.error = false;
        }
    },
    checkDatesOrder(){
      if(this.dates[0] > this.dates[1]){
        const term = this.dates[0];
        this.dates[0] = this.dates[1];
        this.dates[1] = term;
      }
    },
    handleCreateRegister: function(value) {
        this.terminReady = value;
        this.terminReady.nfz = this.setting_nfz;
        this.loading = true;

        this.$api
        .postData(`/rejestracja/createregister`, this.terminReady)
        .then(response => {
            window.location.href = `/rejestracja/podsumowanie/`;
            })
        .catch(error => { console.log(error); this.loading = false; this.showSnackBar('Coś poszło nie tak...'); });

    }
  },
  computed: {
    isComment() { return this.komentarz; },
    isPrzerwa(){
      return this.pre_komunikat;
    },
    dateRangeText() { this.checkDatesOrder(); const output = this.dates.join(' do '); return `od ${output}`; },
    checkLoading() { return this.loading; },
    isError() { return this.error; },
    checkAction() {
      if (this.lekarz == null && this.usluga == null && this.poradnia == null) return 'searchterminlok';
      if (this.lekarz == null && this.usluga == null) return 'searchterminpor';
      if (this.lekarz > 0 && this.usluga > 0) return 'searchterminlekusl';
      if (this.lekarz == null && this.usluga > 0) return 'searchterminusl';
      return 'searchterminlek';
    },

    wybranaUsluga() {
      if (this.usluga==null) return { nazwa: '', cena:0, czas: 0 };
      if (this.uslugi) {
        for(let k in this.uslugi) {
          if (this.uslugi[k].mpusl_id == this.usluga) {
            const wybrana = {
              id: this.uslugi[k].mpusl_id,
              nazwa: this.uslugi[k].mpusl_nazwa,
              cena: this.uslugi[k].cena,
              czas: this.uslugi[k].czas,
              pytanie: this.uslugi[k].pytanie,
              kom_tak: this.uslugi[k].komunikat_tak,
              kom_nie: this.uslugi[k].komunikat_nie,
              nfz: this.uslugi[k].nfz,
            };
            return wybrana;
          }
        }
      }
      return { id: null, nazwa: '', cena:0, czas: 0, pytanie: '', kom_tak: '', kom_nie: '', nfz: 0};
    },

    registerData() {
      this.checkDatesOrder();
       return {
            por: this.poradnia,
            lek: this.lekarz,
            usl: this.usluga,
            lok: this.lokalizacja,
            lokalizacje: this.lokalizacje,
            dataod: this.dates[0],
            datado: this.dates[1] ? this.dates[1] : this.dates[0],
            godzod: this.godzod,
            godzdo: this.godzdo,
            maxdni: 2,
        };
    }
  },
  created() {
    this.url = document.querySelector('body').dataset.url;
    this.loading = true;
      this.$api
      .getData(`/getrejonlinedata`)
      .then(response => {
          this.lekarze = response.data.data.lekarze;
          this.poradnie = response.data.data.poradnie;
          this.uslugi = response.data.data.uslugi;
          this.uslugiAll = this.uslugi;
          this.dates = [response.data.data.dataOd, response.data.data.dataDo];
          this.lokalizacje = response.data.data.lokalizacje;
          this.lokalizacja = response.data.data.lokalizacja;
          this.godzod = response.data.data.godzOd;
          this.godzdo = response.data.data.godzDo;
          this.komentarz = response.data.data.komentarz;
          this.guest = response.data.data.guest;
          this.mindata = response.data.data.dataOd;
          this.maxdata = response.data.data.dataMax;
          this.setting_nfz = response.data.data.setting_nfz;
          this.pre_komunikat = response.data.data.pre_komunikat;
          this.komunikatContent = response.data.data.komunikatContent;
          this.loading = false;
          })
      .catch(error => { console.log(error); this.loading = false; this.showSnackBar('Przepraszamy, usługa chwilowo niedostępna. Spróbuj wkrótce.'); } );

      this.checkIfOdwolana();

  }
};
</script>

<style lang="scss" >
  .v-picker__title {display: none;}
  .searchbutton {
    margin-bottom: 15px;
    .v-btn {
      font-size: 30px;
      padding: 10px 30px !important;
      height: auto !important;
      .v-icon {
        font-size: 30px;
      }
    }
  }
  .results {
    background: $lightbg;
    padding: 30px 0;
  }
  .komentarz {
    border: 1px solid #85abb5; padding: 10px 15px; color: #3a7887; background: rgba(133, 171, 181, 0.1); box-shadow: 0 0 10px rgba(133, 171, 181, 0.2);
  }
  .komunikat_przerwa{
  font-size: 20px;
  border: 2px solid red;
  border-radius: 5px;
  padding: 15px 10px;
  color: white;
  background: rgb(225, 0,0);
  box-shadow: 0 0 10px rgba(133, 171, 181, 0.2);
  margin-bottom: 20px;
}
</style>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
