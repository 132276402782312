<template>

    <v-dialog
           max-width="580px"
           v-model="modal"
           overlay-color="#FFFFFF"
           @click:outside="cancelTermin"
           @keydown="cancelTermin"
         >
           <v-card >
             <slot></slot>
             <v-card-text>
                <v-card-subtitle class="text-h6">
                    <v-text v-if="this.wybranaUsluga.pytanie !== null && ok === false">
                        {{this.wybranaUsluga.pytanie}}
                    </v-text>
                    <v-text v-if="this.wybranaUsluga.kom_nie !== null && ok === true">
                        {{komunikat}}
                    </v-text>
                </v-card-subtitle>
                <v-row class="pt-4" v-if="ok === false">
                  <v-col>
                     <v-btn
                       tile
                       color="error"
                       large
                       block
                       elevation="0"
                       @click="wyswietlok('NO')"
                     >
                       <v-icon left>
                        mdi-close
                       </v-icon>
                       Nie
                     </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                       tile
                       elevation="0"
                       color="success"
                       large
                       block
                       :loading="loading"
                       @click="wyswietlok('YES')"
                     >
                       <v-icon left>
                        mdi-check
                       </v-icon>
                       Tak
                     </v-btn>
                  </v-col>
               </v-row>
               <v-row class="pt-4" v-if="ok === true">
                 <v-col align="center">
                    <v-btn
                      style="width: 50%"
                       tile
                       elevation="0"
                       color="success"
                       large
                       @click="answerAccept"
                     >
                       <v-icon left>
                        mdi-check
                       </v-icon>
                       OK
                     </v-btn>
                  </v-col>
               </v-row>
           </v-card-text>
           </v-card>
         </v-dialog>

   </template>
   <script>
   export default {
     name: 'ModalComponent',
     props: {
       modalTerm: {
         type: Object,
         required: true,
         default: {
           dzien:"0000-00-00",
           godz:"00:00",
           nazwa:"",
           uslugi:[],
           wolny:false,
         }
       },
       llok: {
         type: Number,
         required: true,
       },
       lok: {
         type: Number,
         required: true,
       },
       wybranaUsluga: {
         type: Object,
         required: false,
       },
     },
     methods: {
       cancelTermin() {
         this.modal=false;
         this.$emit('closeModal')
        window.location.href = this.url;
       },
       confirmTermin() {
         const datas = {
           dzien: this.termin.dzien,
           godz: this.termin.godz,
           usl: this.usluga.id,
           usluga: this.usluga.nazwa,
           cena: this.usluga.cena,
           czas: this.usluga.czas,
           llok: this.leklok,
           lok: this.lokalizacja,
         }
         this.$emit('create', datas);
       },
       wyswietlok(click) {
            console.log(click);
            this.clicked = click;
            this.ok=true;
            this.loading=false;
       },
       answerAccept() {
        for(let k in this.uslugi) {
          if (this.uslugi[k].mpusl_id == this.wybranaUsluga.id) {
            this.usluga = this.uslugi[k];
          }
        }
        if(this.clicked === "YES"){
            this.confirmTermin();
        }else{
            this.cancelTermin();
        }
       },
     },
     data() {
       return {
           termin: {
             dzien:"0000-00-00",
             godz:"00:00",
             nazwa:"",
             uslugi:[],
             wolny:false,
           },
           usluga: {id: null, nazwa: ''},
           nazwa: '',
           modal: true,
           leklok: '',
           url: '',
           dostepny: false,
           selectedCena: null,
           selectedCzas: null,
           loading: false,
           ok: false,
           clicked: '',
       };
     },
     mounted() {

         this.termin = this.modalTerm;
         this.leklok = this.llok;
         this.lokalizacja = this.lok;
         this.url = document.querySelector('body').dataset.url;
         this.url = document.querySelector("meta[name='api-url']").content;

      /*  this.photo = this.item.links[0].href;
       this.title = this.item.data[0].title;
       this.description = this.item.data[0].description.substring(0, 200); */
     },
     computed: {
      komunikat() {
        if(this.clicked === "YES"){
          return this.wybranaUsluga.kom_tak;
        }else{
          return this.wybranaUsluga.kom_nie;
        }
      }
     },
   };
   </script>
   <style lang="scss" scoped>
     .outerWrapper {
       max-width: 100%;
       height: 100%;
       position: fixed;
       top: 0;
       left: 0;
       background: rgba(255,255,255, .6);


     }



     .innerWrapper {
       @include box-shadow;
       background: $lightbg;
       padding: 30px;
       max-width: 780px;
       margin: 30px;
       padding: 30px;
       position: fixed;
       top: 50%;

       left: 0;
       right: 0;
       margin: 0px auto;

         @media (min-width: 1024px) {

         }





     }
   </style>
