<template>
<div>
  <v-container>
    <div class="doctorresult">
    <v-row v-for="(data, index) in returnData">
         <v-col
          cols="12"
          xs="12"
          sm="3"
          md="3"
         >
        <div v-if="checkIndexToDisplay(index)">
            <div class="lek-foto"><img :src="foto" :alt="imie"></div>
            <div class="lek-name"><b v-text="imie"></b></div>
            <div class="lek-comment" v-if="komentarz" v-html="komentarz"></div>
            <div class="lek-info" v-if="informacja" v-html="informacja"></div>
        </div>
        <div></div>
         </v-col>
         <v-col
          cols="12"
          xs="12"
          sm="4"
         >
            <div class="lek-plac" v-html="placowkowy(data.nazwaplac, data.daneplacowki)"></div>
         </v-col>

         <v-col
          cols="12"
          xs="12"
          sm="5"
         >
           <div class="results-wrap" v-if="checkTerminy">
              <DayComponent v-for="termin in data.lista"
              :resultUsl="checkUsl"
              :usluga="wybranaUsluga"
              :termin="termin"
              :lok="data.lok"
              :llok="data.llok"
              @makeclick="handleClickTermin"
               @makecreate="beforeHandleMakeCreate">
              </DayComponent>
          </div>
          <v-alert
            v-else
            border="bottom"
            color="pink"
            dark
            tile
          >
            brak wolnych terminów
          </v-alert>
         </v-col>
      </v-row>
      </div>
  </v-container>
  <ModalComponent v-if="modalOpen" :modalTerm="modalTerm" :wybranaUsluga="wybranaUsluga" :nfz="setting_nfz" :llok="llok" :lok="lok" @closeModal="modalOpen = false" @create="handleCreatefromModal">
    <v-card-title
    class="text-caption">
    {{ imie }}
          </v-card-title>
  </ModalComponent>
  <PytanieComponent v-if="pytanieOpen" :modalTerm="modalTerm" :llok="llok" :wybranaUsluga="wybranaUsluga" :lok="lok" @closeModal="pytanieOpen = false" @create="handleMakeCreate">
    <v-card-title
    class="text-caption"
     v-if="usluga.nazwa !== null">{{ usluga.nazwa }}
          </v-card-title>
          <v-card-title
          class="text-caption"
          v-if="usluga.nazwa === null">Pytanie do usługi
          </v-card-title>
  </PytanieComponent>
  </div>
</template>

<script>

import ModalComponent from './ModalComponent.vue';
import DayComponent from './DayComponent.vue';
import PytanieComponent from './PytanieComponent.vue';
export default {
  name: 'ResultComponent',
  props: {
    lekdata: {
      type: Object,
      required: true,
    },
    usl: {
      required: false,
    },
    nfz: {
      required: true,
    },
    lokalizacja: {
      required: true,
    },
    wybranaUsluga: {
      type: Object,
      required: false,
      default: function () {
        return {
          nazwa: '',
          cena: null,
          czas: null,
        }
      }
    },
  },

  watch: {
    lekdata: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    }
  },

  components: {
    DayComponent,
    ModalComponent,
    PytanieComponent,
  },

  mounted() {
    this.lek = this.lekdata.lek;
    this.lok = this.lekdata.returnData.lok;
    this.llok = this.lekdata.returnData.llok;
    this.imie = this.lekdata.danelekarza;
    this.komentarz = this.lekdata.komentarz;
    this.informacja = this.lekdata.informacja;
    this.foto = this.lekdata.lekfoto;
    this.placowka = this.lekdata.returnData.daneplacowki;
    this.nazwaplac = this.lekdata.returnData.nazwaplac;
    // this.terminy = this.lekdata.returnData.lista;
    this.resultUsl = this.usl;
    this.usluga = this.wybranaUsluga;
    this.setting_nfz = this.nfz;
    this.returnData = this.lekdata.returnData;
  },

  data: () => ({
    lek: '',
    lok:'',
    llok: '',
    imie: '',
    foto: '',
    placowka: '',
    terminy: '',
    modalOpen: false,
    pytanieOpen: false,
    modalTerm: null,
    komentarz: false,
    nazwaplac: '',
    resultUsl: null,
    usluga: null,
    setting_nfz: false,
    returnData: [],
  }),
  computed: {
    // placowkowy(nazwa1, nazwa2) {
    //   return `<b>${nazwa1}</b><br>${nazwa2}`;
    // },

    checkUsl() {
      return this.usl;
    },

    checkTerminy() {
      let counter = 0;
      for(let key in this.returnData){
        if (this.returnData[key].lista.length === 0 || !(this.returnData[key].lista) ) counter++;
      }
        if (counter == 0){ return true};
        return false;
    },
  },
  methods: {
    handleClickTermin: function(value)
    {
      this.llok = value.llok;
      this.lok = value.lok;
      this.modalTerm = value;
      this.modalOpen = true;
    },
    placowkowy(nazwa1, nazwa2) {
      return `<b>${nazwa1}</b><br>${nazwa2}`;
    },
    checkIndexToDisplay(index){
      if(index == 0) return true;
      return false;
    },
    beforeHandleMakeCreate: function(term){
      if(this.nfz !== false && this.nfz !== '0' && this.usluga.pytanie !== "" && this.usluga.pytanie !== null ){
          this.modalTerm = term;
          this.llok = term.llok;
          this.lok = term.lok;
          this.pytanieOpen = true;
        }else{
        for(let key in term.uslugi){
          if (term.uslugi[key].id === this.usl){
            this.usluga.cena = term.uslugi[key].cena;
            this.usluga.czas = term.uslugi[key].czas;
            this.usluga.nazwa = term.uslugi[key].nazwa;
          }
        }
          this.handleMakeCreate(term);
        }
    },
    handleMakeCreate: function(value)
    {
        const data = {
          'termin': value.dzien,
          'lek': this.lek,
          'llok': value.llok,
          'plac': value.lok,
          'usl': this.usl,
          'godz': value.godz,
          'cena': this.usluga.cena,
          'czas': this.usluga.czas,
          'usluga': this.usluga.nazwa,
          'usluga_nfz': this.usluga.nfz,
        };
        this.$emit('createregister', data);
    },

    handleCreatefromModal: function(datas)
    {
      let data = datas;
      data.lek = this.lek;
      data.llok = datas.llok;
      data.plac = datas.lok;
      this.$emit('createregister', data);
    },
  }
}
</script>

<style lang="scss" scoped>
  .doctorresult {
    background: #FFF;
    padding: 15px;
    margin-bottom: 15px;
  }
  .lokresult {
    display: flex;
  }
  .lek-foto {
    margin-bottom: 15px;
    img {
    width: 80px;
    height: auto;
    display: block;
    }
  }
  .lek-name {
    font-size: 14px;
    color: $primary-text;
  }
  .lek-plac {
    font-size: 13px;
    color: $primary-text;
  }
  .lek-comment {
    margin-top: 10px;
    background: #dcf6db;
    padding: 10px;
    font-size: 12px;
    color: #549950;
    margin-bottom: 10px;
  }
  .lek-info {
    margin-top: 10px;
    background: #b2dbee;
    padding: 10px;
    font-size: 12px;
    color: #5388a0;
    margin-bottom: 10px;
  }
</style>
