<template>

 <v-dialog
        max-width="580px"
        v-model="modal"
        overlay-color="#FFFFFF"
        @click:outside="cancelTermin"
        @keydown="cancelTermin"
      >
        <v-card>
          <slot></slot>
          <v-card-text>

            <p class="pt-3">{{ termin.dzien | dateParse('YYYY-MM-DD') | dateFormat('DD MMMM YYYY') }}, godz. <b v-html="termin.godz"></b></p>
            <v-select
              :items="termin.uslugi"
              v-model="usluga"
              prepend-inner-icon="mdi-stethoscope"
              tile
              return-object
              outline
              :item-text="usluga => checkUslugaNfz(usluga.nazwa, usluga.cena)"
              item-value="id"
              label="wybierz dostępną usługę"
              @change="checkUsl()"
            ></v-select>

            <v-alert
              dense
              text
              type="success"
              v-if="dostepny"
            >
              Termin dostępny
            </v-alert>
            <v-row v-if="dostepny">
              <v-col v-if="selectedCena && !this.usluga.nfz">
                  Cena: <b>{{ selectedCena | plnfilter }}</b>
              </v-col>
              <v-col v-if="this.usluga.nfz">
                  Cena: <b>NFZ</b>
              </v-col>
              <v-col v-if="selectedCzas">
                  Czas: <b>{{ selectedCzas | czasfilter }}</b>
              </v-col>
            </v-row>
            <v-row v-if="this.nfz === '1' && this.pytanieZatwierdzone === false">
              <v-card-subtitle v-if="this.usluga.pytanie !== null && this.pytanieZatwierdzone === false">
                    <v-text>
                        <b>{{this.usluga.pytanie}}</b>
                    </v-text>
                </v-card-subtitle>
            </v-row>
            <v-row v-else-if="this.nfz === '1' && this.zatwierdzClicked === true">
              <v-card-subtitle>
                  <v-text >
                        <b>{{komunikat()}}</b>
                    </v-text>
                </v-card-subtitle>
            </v-row>
            <v-row class="pt-4" v-if="this.nfz === '1' && this.usluga.pytanie !== null && this.usluga.pytanie !== '' && this.pytanieZatwierdzone === false">
              <v-col>
                 <v-btn
                    tile
                    elevation="0"
                    color="error"
                    large
                    block
                    @click="cancelTermin"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Nie
                  </v-btn>
               </v-col>
               <v-col>
                  <v-btn
                    tile
                    color="success"
                    large
                    block
                    elevation="0"
                    @click="goToConfirm()"
                    :loading="loading"
                  >
                    <v-icon left>
                      mdi-check
                    </v-icon>
                    Tak
                  </v-btn>
               </v-col>
            </v-row>

            <v-row class="pt-4" v-if="this.pytanieZatwierdzone">
              <v-col>
                 <v-btn
                    tile
                    elevation="0"
                    color="primary"
                    large
                    block
                    @click="cancelTermin"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Anuluj
                  </v-btn>
               </v-col>
               <v-col>
                  <v-btn
                    tile
                    color="secondary"
                    large
                    block
                    elevation="0"
                    :disabled="!dostepny"
                    @click="confirmTermin()"
                    :loading="loading"
                  >
                    <v-icon left>
                      mdi-check
                    </v-icon>
                    Zatwierdź
                  </v-btn>
               </v-col>
            </v-row>
        </v-card-text>
        </v-card>
      </v-dialog>

</template>
<script>
export default {
  name: 'ModalComponent',
  props: {
    modalTerm: {
      type: Object,
      required: true,
      default: {
        dzien:"0000-00-00",
        godz:"00:00",
        nazwa:"",
        uslugi:[],
        wolny:false,
      }
    },
    llok: {
      type: Number,
      required: true,
    },
    nfz: {
      type: Boolean,
      required: true,
    },
    lok: {
      type: Number,
      required: true,
    },
  },
  methods: {
    cancelTermin() {
      this.usluga.pytanie = null;
      if(this.setting_nfz){
        this.pytanieZatwierdzone = false;
      }
      this.modal=false;
      this.$emit('closeModal')
    },
    goToConfirm() {
      this.pytanieZatwierdzone=true;
      this.zatwierdzClicked=true;
    },
    confirmTermin() {
      const datas = {
        termin: this.termin.dzien,
        godz: this.termin.godz,
        usl: this.usluga.id,
        usluga: this.usluga.nazwa,
        cena: this.usluga.cena,
        czas: this.usluga.czas,
        llok: this.leklok,
        lok: this.lokalizacja,
        usluga_nfz: this.usluga.nfz,
      }
      this.$emit('create', datas);
    },
    checkUsl() {
      this.checkNfz();
      this.usluga.pytanie = null;
      this.zatwierdzClicked=false;
      if(this.setting_nfz){
        this.$api.getData('/rejestracja/getuslugabyid?id=' + this.usluga.id)
      .then(response => {
        if(response.data.pytanie && this.nfz !== '0'){
            this.usluga.pytanie = response.data.pytanie;
            this.usluga.kom_tak = response.data.komunikat_tak;
            this.usluga.nfz = response.data.nfz;
            this.pytanieZatwierdzone = false;
        }else{
            this.usluga.nfz = response.data.nfz;
            this.pytanieZatwierdzone = true;
        }
      }).catch(error => console.log(error));
      }
        this.dostepny = false;
        this.loading = true;
        const data = {
            datallok: `${this.termin.dzien}-${this.leklok}`,
            godz: this.termin.godz,
            czas: '',
        };
        this.$api
        .postData(`/szukaj/sprawdzmozliwosc`, data)
        .then(response => {
            this.loading = false;
            this.dostepny = true;
            this.selectedCena =this.usluga.cena;
            this.selectedCzas =this.usluga.czas;
            })
        .catch(error => console.log(error));

    },
    checkNfz() {
      if(this.nfz !== false && this.nfz !== '0' && this.usluga.pytanie !== null && this.usluga.pytanie !== ''){
        return this.pytanieZatwierdzone = false;
      }else{
        return this.pytanieZatwierdzone = true;
      }
    },
    komunikat(){
      return this.usluga.kom_tak;
    },
    checkUslugaNfz(nazwa, cena){
      if(this.usluga_nfz || cena == "0.00"){
        return nazwa + ' - ' + "NFZ";
      }else{
        return nazwa + ' - ' + cena + ' zł';
      }
    }
  },
  data() {
    return {
        termin: {
          dzien:"0000-00-00",
          godz:"00:00",
          nazwa:"",
          uslugi:[],
          wolny:false,
        },
        usluga: {id: null, nazwa: ''},
        nazwa: '',
        modal: true,
        leklok: '',
        url: '',
        dostepny: false,
        selectedCena: null,
        selectedCzas: null,
        loading: false,
        pytanieZatwierdzone: true,
        setting_nfz: false,
        zatwierdzClicked: false,
    };
  },
  mounted() {
      this.usluga.pytanie = null;
      this.setting_nfz = this.nfz;
      this.termin = this.modalTerm;
      this.leklok = this.llok;
      this.lokalizacja = this.lok;
      this.url = document.querySelector('body').dataset.url;
   /*  this.photo = this.item.links[0].href;
    this.title = this.item.data[0].title;
    this.description = this.item.data[0].description.substring(0, 200); */
  },
};
</script>
<style lang="scss" scoped>
  .outerWrapper {
    max-width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255, .6);


  }



  .innerWrapper {
    @include box-shadow;
    background: $lightbg;
    padding: 30px;
    max-width: 780px;
    margin: 30px;
    padding: 30px;
    position: fixed;
    top: 50%;

    left: 0;
    right: 0;
    margin: 0px auto;

      @media (min-width: 1024px) {

      }





  }
</style>
