
<template v-if="wolnytermin">
  <li v-html="termButton" :class="{'tlekli': true, 'notlekli': !wolnytermin}"></li>
</template>


<script>
export default {
  name: 'TerminComponent',
   props: {
    term: {
      type: Object,
      required: true,
    },
    dayUsl: {
      required: true
    },
    nfz: {
      required: true
    }
  },
  data: () => ({
    termin: null,
    terminUsl: null,
    nfz_platna: 0,
  }),
  mounted() {
    this.termin = this.term;
    this.terminUsl = this.dayUsl;
    this.nfz_platna = this.nfz;
  },
  computed: {
    wolnytermin: function() {
      return this.term.wolny;
    },
    termButton: function() {
      let uslNazwa='wybierz uslugę';
      if (this.terminUsl) {
        for(let key in this.term.uslugi){
          if (this.term.uslugi[key].id === this.terminUsl){
            uslNazwa = this.term.uslugi[key].infocena;
          }
        }
      }
      if (this.nfz_platna) uslNazwa = "NFZ";
      if (!(this.wolnytermin)) uslNazwa = 'termin zajęty';

      return `<div></small><strong>${this.term.godz}</strong><small>${uslNazwa}</small></div>`;
    }
  },
}

</script>

<style>
.tlekli {
  padding: 7.5px;
  width: 85px;
  cursor: pointer;
  margin: 0;
  list-style: none;
}

.tleklist .notlekli {
  cursor: default;
}

.tlekli div {
  border: 1px solid #3a7887;
  border-radius: 5px;
  overflow: hidden;
}
.tleklist .notlekli div {
  border: 1px solid #b71c1c;
}



.tlekli div strong {
    display: block;
    background: #3a7887;
    color: #FFF;
    text-align: center;
    padding: 3px 0;
    font-weight: bold;
    font-size: 18px;
}
.tleklist .notlekli div strong {
  /* background: #85abb5; */
  background: #b71c1c;
}


.tlekli div small {
    display: block;
    text-align: center;
    padding: 4px 1px;
    font-size: 12px;
    line-height: 1.2em;
}

.tleklist .notlekli div small {
  color:  #85abb5;
  color: #b71c1c;
}

.tlekli div:hover {
  opacity: .8;
}





</style>
