<template>
  <div>
    <LoadingComponent v-show="checkLoading"></LoadingComponent>
    <div class="pt-6 mb-6 bottomlinks">
      <span class="primary--text" @click="getTxtContent('pom')">Pomoc</span> <span class="primary--text" @click="getTxtContent('reg')">Regulamin</span> <span class="primary--text" @click="getTxtContent('pol')">Polityka prywatności</span>
    </div>
    <v-dialog
        tile
        transition="dialog-bottom-transition"
        max-width="980"
        v-model="contentModal"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >{{ contentTitle }}</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-4" v-html="contentTxt"></div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="contentModal = false"
              >Zamknij</v-btn>
            </v-card-actions>
          </v-card>

      </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ContentpageComponent',
    data: () => ({
      contentModal: false,
      contentTxt: null,
      contentType: null,
      loading: false,
      contentTitle: null,
      url: null,
    }),
    methods: {
      getTxtContent(type) {
        this.loading = true;
        if (type==='pom') this.contentTitle = 'Pomoc';
        if (type==='reg') this.contentTitle = 'Regulamin';
        if (type==='pol') this.contentTitle = 'Polityka prywatności';


      this.$api
      .getData(`/szukaj/pobierzopisowe?typ=${type}&lng=pl`)
      .then(response => {
          this.contentTxt = response.data;
          this.loading = false;
          this.contentModal = true;
          })
      .catch(error => { console.log(error); this.loading = false;  } );
      }
    },
    computed: {
      checkLoading() { return this.loading; },
    },
    created() {
      this.url = document.querySelector('body').dataset.url;
    },
  }
</script>

<style lang="scss" scoped>
  .bottomlinks {
    text-align: center;
  }
  .bottomlinks span { font-size: 14px; cursor: pointer; margin: 0 6px;}
  .bottomlinks span:hover {
    text-decoration: underline;
  }
</style>
