<template>
  <div v-if="terms">
    <div class="dayheader">{{ dayHeader | dateParse('YYYY-MM-DD') | dateFormat('DD MMMM YYYY') }} <span>{{ dayHeader | dateParse('YYYY-MM-DD') | dateFormat('dddd') }}</span></div>
    <ul class="tleklist">
    <TerminComponent v-for="term in terms" :term="term" :dayUsl="dayUsl" :nfz="usluga.nfz" :key="term.godz" @click.native="handleModalOpen(term)"></TerminComponent>
    </ul>
  </div>

</template>

<script>
import TerminComponent from './TerminComponent.vue';
export default {
  name: 'DayComponent',
   props: {
    termin: {
      required: true,
    },
    resultUsl: {
      required: true,
    },
    lok: {
      required: true,
    },
    llok: {
      required: true,
    },
    usluga: {
      required: false,
    },
  },
  components: {
    TerminComponent,
  },
  computed: {
    dayHeader() {
      return this.terms[0].dzien;
      // + ' <small>(' + this.terms[0].nazwa + ')</small>';
    }
  },
  data: () => ({
    terms: null,
    dayUsl: null,
    lokalizacja: null,
    lekalokalizacja: null,
  }),
  mounted() {
    this.terms = this.termin;
    this.lokalizacja = this.lok;
    this.lekalokalizacja = this.llok;
    this.terms.sort((a,b)=> a.gInt - b.gInt );
    this.dayUsl = this.resultUsl;
    console.log(this.terms);
  },
  methods: {
    handleModalOpen: function(term) {
      term.lok = this.lokalizacja;
      term.llok = this.lekalokalizacja;
      if (term.wolny) {
        if (this.dayUsl>0) {
          this.$emit('makecreate', term);
        } else {
          this.$emit('makeclick', term);
        }
      }
    }
  }
}

</script>

<style>
  .v-card__title { background: #f2f5f6; color: #3a7887; }
</style>

<style lang="scss" scoped>
.tleklist {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-left: 0px;
  padding-right: 5px;
}
.dayheader {
  color: $primary-text;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid $primary-text;
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-left: 7.5px;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #549950;
  }
}
</style>
