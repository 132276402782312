<template>
  <div>
<div class="modal-bg">
  <div class="modal">
    <v-card
    class="mx-auto"
    max-width="500"
    >
      <div class="text-xs-center">
        <v-card-title>
          {{title}}
        </v-card-title>

        <v-card-subtitle>
          {{text}}
        </v-card-subtitle>
      </div>
      <v-card-actions class="justify-center">
        <v-btn
          color="info"
          @click="closeModal"
        >
          Zamknij
        </v-btn>
      </v-card-actions>
  </v-card>
  </div>
</div>
  </div>
</template>

<script>
  export default {
    name: 'PopupNotificationComponent',
    data () {
     return {
      defaultOptions: {
      },
     }
    },
    props: {
      title: {
        required: true,
      },
      text: {
        required: true,
      },
    },
    methods: {
      closeModal: function () {
        this.$emit('snack', false);
      }
    }
  }
</script>

<style lang="scss" scoped>
.modal-bg{
  /**fixed modal to viewport */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  /* Przyciemnione tło poza modalem */
  background-color: rgba(0,0,0,0.5);

  /* Wyśrodkowanie modala */
  display: flex;
  align-items: center;
  justify-content: center;
}
  .modal{
    background: #FFF;
    // padding: 50px 100px;
    width: 500px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 10px 5px 2px rgba(0,0,0,0.1);
  }

  .modal .close-btn{
    /*Modal w prawym górnym rogu */
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    /* Zmiana defaultowych ustawien buttona */
    background: none;
    cursor: pointer;
  }
</style>

